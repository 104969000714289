import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiItemResponse } from '@frontend/common';
import { environment } from '@frontend/env/environment';
import { LoginRequest, LoginResponse, RegisterRequest } from './auth.type';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public url = 'user';

  constructor(private http: HttpClient) {}

  private getApiUrl(url: string): string {
    if (url.substring(0, 1) === '/') {
      return `${environment.apiUrl}${url}`;
    } else {
      return `${environment.apiUrl}/${url}`;
    }
  }

  login(params: LoginRequest): Observable<ApiItemResponse<LoginResponse>> {
    return this.http.post<ApiItemResponse<LoginResponse>>(
      `${environment.apiUrl}/user/login`,
      params
    );
  }

  register(
    params: RegisterRequest,
    ref?: string
  ): Observable<ApiItemResponse<LoginResponse>> {
    return this.http.post<ApiItemResponse<LoginResponse>>(
      `${environment.apiUrl}/user/register?ref=` + ref,
      params
    );
  }

  profile(username: string): Observable<ApiItemResponse<any>> {
    return this.http.get<ApiItemResponse<any>>(
      `${environment.apiUrl}/user/showInfoUserByUserName?username=` + username
    );
  }

  forgotPassword(email: string) {
    return this.http.post(
      `${environment.apiUrl}/user/requestForgotPassword?email=` + email,
      null
    );
  }

  changePassword(data: any) {
    return this.http.post(`${environment.apiUrl}/user/resetPasswordUser`, data);
  }

  saveIpLogin(params: any): Observable<ApiItemResponse<LoginResponse>> {
    return this.http.post<ApiItemResponse<LoginResponse>>(
      `${environment.apiUrl}/history-login/save`,
      { ...params }
    );
  }

  getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  check2fa(param: {
    id: number;
    username: string;
    code: number;
  }): Observable<ApiItemResponse<LoginResponse>> {
    const params = new HttpParams({
      fromObject: { ...param },
    });
    return this.http.get<ApiItemResponse<LoginResponse>>(
      `${environment.apiUrl}/user/check2FACode`,
      { params }
    );
  }

  loginGoogle(data: any) {
    return this.http.post(`${environment.apiUrl}/user/loginGoogle`, data);
  }
}
